<template>
  <router-view />
</template>

<style lang="scss">
html,
body {
  background: #f7f7f7;
  overflow-x: scroll;
}
.container {
  margin: 0 auto;
  width: 1103px !important;
  max-width: 1103px !important;
}
</style>
